import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import React from "react";

const OpenGraph = (props) => {
  const { siteUrl, type, title, description, image, locale } = props;

  return (
    <Helmet>
      <meta property="og:locale" content={locale} />
      <meta property="og:url" content={siteUrl} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />

      {image && image.src && (
        <meta property="og:image" content={`${siteUrl}${image.src}`} />
      )}

      {image && image.mediaType && (
        <meta property="og:image:type" content={image.mediaType} />
      )}
      {image && image.width && (
        <meta property="og:image:width" content={`${image.width}`} />
      )}
      {image && image.height && (
        <meta property="og:image:height" content={`${image.height}`} />
      )}
      {image && image.name && (
        <meta property="og:image:alt" content={image.name} />
      )}
    </Helmet>
  );
};

export default OpenGraph;

OpenGraph.defaultProps = {
  type: "website",
  locale: "pt_PT",
  description: "",
};

OpenGraph.propTypes = {
  type: PropTypes.string,
  locale: PropTypes.string,
  siteUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    mediaType: PropTypes.string,
    name: PropTypes.string,
  }),
};
