import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import SVGLogo from "./svg-logo";
import styled from "styled-components";

const StyledLink = styled(Link)`
  display: flex;
`;

const Logo = ({ isWhite }) => {
  return (
    <StyledLink aria-label="Página inicial" to="/">
      <SVGLogo isWhite={isWhite} />
    </StyledLink>
  );
};

Logo.propTypes = {
  isWhite: PropTypes.bool,
};

export default Logo;
