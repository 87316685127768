import { graphql, useStaticQuery } from "gatsby";
import { isValidString } from "@utilities/string";

export const useImage = () => {
  const data = useStaticQuery(
    graphql`
      query AllImagesQuery {
        default: allFile(
          filter: { internal: { mediaType: { regex: "/image//" } } }
        ) {
          nodes {
            name
            extension
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        highres: allFile(
          filter: { internal: { mediaType: { regex: "/image//" } } }
        ) {
          nodes {
            name
            extension
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    `
  );

  const getFluidImageName = (name, highres = false) => {
    if (name === undefined || !isValidString(name)) {
      return null;
    }

    if (data.default.nodes.length === 0 || data.highres.nodes.length === 0) {
      return null;
    }

    if (highres) {
      // Case Sensitive
      const image = data.highres.nodes.find((image) => image.name === name);

      return image?.childImageSharp?.fluid || null;
    }

    // Case Sensitive
    const image = data.default.nodes.find((image) => image.name === name);

    return image?.childImageSharp?.fluid || null;
  };

  const getFluidImageNameAndExtension = (name, extension, highres = false) => {
    if (!isValidString(name) || !isValidString(extension)) {
      return null;
    }

    if (data.default.nodes.length === 0 || data.highres.nodes.length === 0) {
      return null;
    }

    if (highres) {
      // Case Sensitive
      const image = data.highres.nodes.find(
        (image) => image.name === name && image.extension === extension
      );

      return image?.childImageSharp?.fluid || null;
    }

    // Case Sensitive
    const image = data.default.nodes.find(
      (image) => image.name === name && image.extension === extension
    );

    return image?.childImageSharp?.fluid || null;
  };

  return {
    getFluidImageName,
    getFluidImageNameAndExtension,
  };
};
