import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import React from "react";

const Button = ({ className, children, onClick, ...rest }) => {
  const handleClickAction = () => {
    onClick?.();
  };

  return (
    <button className={className} onClick={handleClickAction} {...rest}>
      {children}
    </button>
  );
};

Button.defaultProps = {
  onClick: () => {},
  children: "Button",
};

Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  border: PropTypes.string,
  bg: PropTypes.string,
};

const borders = {
  none: css`
    border: none;
  `,
  blue: css`
    border-color: #060038;
  `,
  white: css`
    border-color: white;
  `,
};

const makeBorderStyles = () => css`
  border-style: solid;
  border-radius: 5px;
  border-width: 1px;
  ${(props) => {
    let currentBorder = null;

    if (props.border && Object.keys(borders).includes(props.border)) {
      currentBorder = borders[props.border];
    }

    return currentBorder || borders.none;
  }};
`;

const bgColors = {
  none: css`
    background: transparent;
  `,
  orange: css`
    background-color: #e7193b;
  `,
  white: css`
    background-color: white;
  `,
};

const makeBackgroundStyles = (props) => {
  let selectedBgColor = null;

  if (props.bg && Object.keys(bgColors).includes(props.bg)) {
    selectedBgColor = bgColors[props.bg];
  }

  return selectedBgColor || bgColors.orange;
};

const colors = {
  black: css`
    color: black;
  `,
  white: css`
    color: white;
  `,
  blue: css`
    color: #060038;
  `,
};

const makeColorStyles = (props) => {
  let selectedColor = null;

  if (props.color && Object.keys(colors).includes(props.color)) {
    selectedColor = colors[props.color];
  }

  return selectedColor || colors.white;
};

const sizes = {
  medium: css`
    width: 112px;
    height: 30px;
  `,
  "medium-large": css`
    width: 140px;
    height: 39px;
  `,
  large: css`
    width: 160px;
    height: 39px;
  `,
  "extra-large": css`
    width: 100%;
    height: 49px;
  `,
};

const makeSizeStyles = (props) => {
  let selectedSize = null;

  if (props.size && Object.keys(sizes).includes(props.size)) {
    selectedSize = sizes[props.size];
  }

  return selectedSize || sizes.medium;
};

const StyledButton = styled(Button)`
  /* falta aqui media query */
  padding: 4px;
  font-size: 14px;
  cursor: pointer;
  ${makeColorStyles};
  ${makeBorderStyles};
  ${makeBackgroundStyles};
  ${makeSizeStyles};

  &:disabled {
    background: #dddddd;
    cursor: not-allowed;
    pointer-events: all;
  }
  /* all custom passed styles, should be here, to override it */
  /* width and height is temporary */
  /* ${(props) => props.width ?? "auto"} */
`;

export default StyledButton;
