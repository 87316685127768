import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const Logo = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 408.37 51.68" {...props}>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path d="M91.79,27.24A17.63,17.63,0,0,0,94,18.38,17.65,17.65,0,0,0,84.59,2.32,19.94,19.94,0,0,0,75,0H51.41V51.68h12.4V36.84H74.07l8.2,14.84H96.74L86,33.59A18.54,18.54,0,0,0,91.79,27.24Zm-11.3-4.43a7.4,7.4,0,0,1-2.91,2.81,8.3,8.3,0,0,1-4,1H63.81V10.26h9.82a8.3,8.3,0,0,1,4,1A7.44,7.44,0,0,1,80.49,14a8.25,8.25,0,0,1,1.11,4.39A8.42,8.42,0,0,1,80.49,22.81Z" />
        <path d="M108,2a15,15,0,0,0-5.54,5.31,14.28,14.28,0,0,0-2.07,7.64,12.72,12.72,0,0,0,1.37,5.76,14.74,14.74,0,0,0,3.73,4.73,15.44,15.44,0,0,0-3.88,4.91,13.26,13.26,0,0,0-1.44,6.09,14.56,14.56,0,0,0,2.11,7.79,15.63,15.63,0,0,0,13.54,7.49h21.64V41.27h-19.5a5.82,5.82,0,0,1-2.8-.67A5.13,5.13,0,0,1,112.39,36a4.94,4.94,0,0,1,.74-2.73,5.64,5.64,0,0,1,2-1.92,5.35,5.35,0,0,1,2.8-.74h17V20.3H117.56a5.42,5.42,0,0,1-2.59-.63,4.83,4.83,0,0,1-2.58-4.31,4.72,4.72,0,0,1,.7-2.59A5.16,5.16,0,0,1,115,11a5.22,5.22,0,0,1,2.59-.66l19.86.07V0H115.64A15.29,15.29,0,0,0,108,2Z" />
        <path d="M150.78,2a15,15,0,0,0-5.54,5.31,14.28,14.28,0,0,0-2.07,7.64,12.72,12.72,0,0,0,1.37,5.76,14.74,14.74,0,0,0,3.73,4.73,15.44,15.44,0,0,0-3.88,4.91A13.26,13.26,0,0,0,143,36.4a14.56,14.56,0,0,0,2.11,7.79,15.63,15.63,0,0,0,13.54,7.49h21.63V41.27H160.74a5.82,5.82,0,0,1-2.8-.67A5.13,5.13,0,0,1,155.21,36,4.94,4.94,0,0,1,156,33.3a5.64,5.64,0,0,1,2-1.92,5.35,5.35,0,0,1,2.8-.74h17V20.3H160.38a5.42,5.42,0,0,1-2.59-.63,4.83,4.83,0,0,1-2.58-4.31,4.72,4.72,0,0,1,.7-2.59A5.16,5.16,0,0,1,157.79,11a5.22,5.22,0,0,1,2.59-.66l19.85.07V0H158.46A15.29,15.29,0,0,0,150.78,2Z" />
        <path d="M277.92,27.24a17.73,17.73,0,0,0,2.21-8.86,17.62,17.62,0,0,0-9.41-16.06A20,20,0,0,0,261.16,0H237.53V51.68h12.41V36.84H260.2l8.19,14.84h14.47L272.09,33.59A18.54,18.54,0,0,0,277.92,27.24Zm-11.3-4.43a7.47,7.47,0,0,1-2.91,2.81,8.33,8.33,0,0,1-3.95,1h-9.82V10.26h9.82a8.33,8.33,0,0,1,3.95,1A7.51,7.51,0,0,1,266.62,14a8.25,8.25,0,0,1,1.11,4.39A8.42,8.42,0,0,1,266.62,22.81Z" />
        <path d="M294.09,2a15,15,0,0,0-5.54,5.31,14.28,14.28,0,0,0-2.07,7.64,12.72,12.72,0,0,0,1.37,5.76,14.74,14.74,0,0,0,3.73,4.73,15.44,15.44,0,0,0-3.88,4.91,13.26,13.26,0,0,0-1.44,6.09,14.64,14.64,0,0,0,2.1,7.79,15.67,15.67,0,0,0,13.55,7.49h21.63V41.27H304.05a5.79,5.79,0,0,1-2.8-.67,5,5,0,0,1-2-1.88,5.1,5.1,0,0,1-.73-2.69,5,5,0,0,1,.73-2.73,5.67,5.67,0,0,1,2-1.92,5.33,5.33,0,0,1,2.8-.74h17V20.3H303.68a5.41,5.41,0,0,1-2.58-.63,4.64,4.64,0,0,1-1.88-1.77,4.74,4.74,0,0,1-.7-2.54,4.72,4.72,0,0,1,.7-2.59A5.08,5.08,0,0,1,301.1,11a5.21,5.21,0,0,1,2.58-.66l19.86.07V0H301.76A15.28,15.28,0,0,0,294.09,2Z" />
        <path d="M336.9,2a15.07,15.07,0,0,0-5.53,5.31,14.28,14.28,0,0,0-2.07,7.64,12.72,12.72,0,0,0,1.37,5.76,14.74,14.74,0,0,0,3.73,4.73,15.44,15.44,0,0,0-3.88,4.91,13.26,13.26,0,0,0-1.44,6.09,14.64,14.64,0,0,0,2.1,7.79,15.67,15.67,0,0,0,13.55,7.49h21.63V41.27H346.87a5.79,5.79,0,0,1-2.8-.67,5,5,0,0,1-2-1.88,5.11,5.11,0,0,1-.74-2.69,5,5,0,0,1,.74-2.73,5.67,5.67,0,0,1,2-1.92,5.33,5.33,0,0,1,2.8-.74h17V20.3H346.5a5.41,5.41,0,0,1-2.58-.63A4.64,4.64,0,0,1,342,17.9a5,5,0,0,1,0-5.13A5.08,5.08,0,0,1,343.92,11a5.21,5.21,0,0,1,2.58-.66l19.86.07V0H344.58A15.34,15.34,0,0,0,336.9,2Z" />
        <path d="M386.07,41.12a1.35,1.35,0,0,1-.77-.18.72.72,0,0,1-.26-.63.93.93,0,0,1,.18-.55,1.07,1.07,0,0,1,.34-.34l13.88-9.3A16.73,16.73,0,0,0,405.3,24a16.57,16.57,0,0,0,1.81-7.86A16.1,16.1,0,0,0,405,8a15.54,15.54,0,0,0-5.91-5.83A17.59,17.59,0,0,0,390.21,0H374.48V10.56H389a7.06,7.06,0,0,1,3.28.7,5.18,5.18,0,0,1,2.92,4.91,6.21,6.21,0,0,1-.78,3,7.13,7.13,0,0,1-2.25,2.47L376.7,32.71a11.57,11.57,0,0,0-3.44,3.91A10,10,0,0,0,372,41.49a10.22,10.22,0,0,0,1.33,5.13,9.7,9.7,0,0,0,3.77,3.69,11.26,11.26,0,0,0,5.61,1.37h25.69V41.12Z" />
        <rect x="16.81" y="14.45" width="12.33" height="37.23" />
        <polygon points="0 10.34 15.99 10.34 29.97 10.34 45.8 10.34 45.8 0 0 0 0 10.34" />
        <polygon points="0 10.34 15.99 10.34 29.97 10.34 45.8 10.34 45.8 0 0 0 0 10.34" />
        <rect x="202.79" y="14.45" width="12.33" height="37.23" />
        <polygon points="185.99 10.34 201.98 10.34 215.96 10.34 231.78 10.34 231.78 0 185.99 0 185.99 10.34" />
      </g>
    </g>
  </svg>
);

const StyledLogo = styled(Logo)`
  height: 14px;
  fill: ${(props) => {
    if (props.isWhite) {
      return "white";
    }
    return props.theme.variant === "light" ? "#ffffff" : "#060038";
  }};
`;

Logo.propTypes = {
  className: PropTypes.string,
  isWhite: PropTypes.bool,
};

export default StyledLogo;
