import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";
import OpenGraph from "./open-graph";
import PropTypes from "prop-types";
import React from "react";
import TwitterTags from "./twitter-tags";
import { useImage } from "@queries/use-image";

function SEO({ title, description, lang, image }) {
  // TODO: export this to a pool of queries folder
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            lang
            siteUrl
          }
        }
      }
    `
  );

  const { getFluidImageNameAndExtension } = useImage();
  const defaultSeoImage = getFluidImageNameAndExtension(
    "brown-dashboard-math",
    "jpg"
  );
  const metaTitle = title || site.siteMetadata.title;
  const metaDescription = description || site.siteMetadata.description;
  const metaImage = image ?? defaultSeoImage;

  return (
    <React.Fragment>
      <Helmet
        htmlAttributes={{ lang }}
        title={metaTitle}
        meta={[
          { name: "MobileOptimized", content: "320" },
          { name: "HandheldFriendly", content: "true" },
          { name: "google", content: "notranslate" },
          { name: "referrer", content: "no-referrer-when-downgrade" },
          { name: `description`, content: metaDescription },
        ]}
      />

      <OpenGraph
        siteUrl={site.siteMetadata.siteUrl}
        title={metaTitle}
        description={metaDescription}
        image={metaImage}
      />

      <TwitterTags
        // siteAuthor="@_twitter_nickName"
        siteUrl={site.siteMetadata.siteUrl}
        title={metaTitle}
        description={metaDescription}
        image={metaImage}
      />
    </React.Fragment>
  );
}

SEO.defaultProps = {
  title: "",
  lang: `pt`,
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number,
    width: PropTypes.number,
    mediaType: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default SEO;
