import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import React from "react";

const TwitterTags = (props) => {
  const { siteUrl, title, description, image, siteAuthor } = props;

  // https://developer.twitter.com/en/docs/tweets/optimize-with-cards/overview/markup
  const titleCharLimit = 70;

  return (
    <Helmet>
      <meta name="twitter:title" content={title.substring(0, titleCharLimit)} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:description" content={description} />

      {siteAuthor && <meta name="twitter:site" content={siteAuthor} />}
      {siteAuthor && <meta name="twitter:creator" content={siteAuthor} />}

      {image && image.src && (
        <meta name="twitter:image" content={`${siteUrl}${image.src}`} />
      )}

      {image && image.src && (
        <meta name="twitter:image:src" content={`${siteUrl}${image.src}`} />
      )}

      {image && image.width && (
        <meta name="twitter:image:width" content={`${image.width}`} />
      )}
      {image && image.height && (
        <meta name="twitter:image:height" content={`${image.height}`} />
      )}
      {image && image.name && (
        <meta name="twitter:image:alt" content={image.name} />
      )}
    </Helmet>
  );
};

export default TwitterTags;

TwitterTags.defaultProps = {
  description: "",
};

TwitterTags.propTypes = {
  siteAuthor: PropTypes.string,
  siteUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    name: PropTypes.string,
  }),
};
